import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Form, TextArea, Typography } from 'gazprom-ui-lib';

import { USER_AGREEMENT_ENUM } from '../userAgreementForm.utils';

const FormDescription = () => {
  return (
    <>
      <Typography.Text strong size="16">
        {t('user_agreement_text_for_users')}
      </Typography.Text>
      <Form.Item
        normalize={normalizeTextField}
        name={USER_AGREEMENT_ENUM.DESCRIPTION}
        rules={[formRules.required, { max: 250 }]}>
        <TextArea placeholder={t('common_enter_text')} maxLength={250} showCount />
      </Form.Item>
    </>
  );
};

export default FormDescription;
