import { t } from 'tools/i18n';

import { EMPLOYEE_GROUPS_FILTER_ENUM } from 'services/employee-groups-new/employeeGroupsNewApiService.types';

export const WORK_METHOD_OPTIONS = [
  {
    label: t('common_default'),
    value: EMPLOYEE_GROUPS_FILTER_ENUM.NOT_SET,
  },
  {
    label: t('employee_group_work_method_only_watch'),
    value: EMPLOYEE_GROUPS_FILTER_ENUM.TRUE,
  },
  {
    label: t('employee_group_work_method_no_watch'),
    value: EMPLOYEE_GROUPS_FILTER_ENUM.FALSE,
  },
];

export const LINK_SETTLEMENT_OPTIONS = [
  {
    label: t('common_default'),
    value: EMPLOYEE_GROUPS_FILTER_ENUM.NOT_SET,
  },
  {
    label: t('employee_group_has_settlement'),
    value: EMPLOYEE_GROUPS_FILTER_ENUM.TRUE,
  },
  {
    label: t('employee_group_no_settlement'),
    value: EMPLOYEE_GROUPS_FILTER_ENUM.FALSE,
  },
];

export const SSO_REGISTERED_OPTIONS = [
  {
    label: t('common_default'),
    value: EMPLOYEE_GROUPS_FILTER_ENUM.NOT_SET,
  },
  {
    label: t('employee_group_mobile_registered'),
    value: EMPLOYEE_GROUPS_FILTER_ENUM.TRUE,
  },
  {
    label: t('employee_group_mobile_not_registered'),
    value: EMPLOYEE_GROUPS_FILTER_ENUM.FALSE,
  },
];
