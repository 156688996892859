import { useDispatch } from 'react-redux';
import { t } from 'tools/i18n';

import { EMPLOYEE_GROUPS_FILTER_ENUM } from 'services/employee-groups-new/employeeGroupsNewApiService.types';
import { setIsChanged } from 'slices/employeeGroupsSlice';

import { Form, Select, Typography } from 'gazprom-ui-lib';

import { EMPLOYEE_GROUPS_FORM_ENUM } from 'pages/employee-groups/employee-groups-create/employeeGroupsCreate.utils';

import s from './StructureFilter.module.scss';

interface Props {
  title: string;
  fieldName: EMPLOYEE_GROUPS_FORM_ENUM;
  options: {
    label: string;
    value: EMPLOYEE_GROUPS_FILTER_ENUM;
  }[];
}

const StructureFilter = (props: Props) => {
  const { title, options, fieldName } = props;

  const dispatch = useDispatch();
  const form = Form.useFormInstance();

  const handleChange = (value: EMPLOYEE_GROUPS_FILTER_ENUM | undefined) => {
    form.setFieldValue(fieldName, value);
    dispatch(setIsChanged(true));
  };

  return (
    <Form.Item
      className={s.wrapper}
      label={
        <Typography.Text weight="400" size="14">
          {title}
        </Typography.Text>
      }
      name={fieldName}>
      <Select
        onChange={handleChange}
        allowClear
        placeholder={t('common_select_second')}
        options={options}
      />
    </Form.Item>
  );
};

export default StructureFilter;
