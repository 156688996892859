import cn from 'classnames';
import { t } from 'tools/i18n';

import { useGetPayslipByYearQuery } from 'services/payslip/payslipApiService';

import { Flex, Select, Typography } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import s from './PayslipSync.module.scss';
import { MONTH_NAMES } from './paylipSync.utils';

interface Props {
  selectedPayslipMonth: string | null;
  handleSelectPayslipMonth: (payslipMonth: string) => void;
  selectedYear: number;
  handleChangeSelectedYear: (year: number) => void;
}

const PayslipSync = (props: Props) => {
  const { selectedPayslipMonth, handleSelectPayslipMonth, selectedYear, handleChangeSelectedYear } =
    props;

  const {
    data,
    isLoading: isPayslipByYearLoading,
    isFetching: isPayslipByYearFetching,
  } = useGetPayslipByYearQuery({
    year: selectedYear,
  });

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const yearOptions = Array.from({ length: currentYear - 2023 + 1 }, (_, i) => 2023 + i);
  const options = yearOptions.map((year) => ({ value: year, label: year.toString() }));

  const isLoading = isPayslipByYearLoading || isPayslipByYearFetching;

  return (
    <Flex vertical gap="16" className={s.wrapper}>
      <Flex fullWidth justify="space-between" align="center">
        <Flex vertical>
          <Typography.Text size="14" weight="500">
            {t('1c_synchronization_payslip_periods_title')}
          </Typography.Text>
          <Typography.Text type="secondary">
            {t('1c_synchronization_payslip_periods_description')}
          </Typography.Text>
        </Flex>
        <Select value={selectedYear} onChange={handleChangeSelectedYear} options={options} />
      </Flex>
      <Flex className={s.contentWrapper}>
        <WithLoader isLoading={isLoading}>
          <Flex fullWidth wrap="wrap" gap="8">
            {data?.map((item) => (
              <Flex
                vertical
                className={cn(s.payslipMonthItem, {
                  [s.payslipMonthItemSelected]: selectedPayslipMonth === item.month,
                  [s.payslipMonthItemDisabled]:
                    selectedYear === currentYear && Number(item.month) >= currentMonth,
                })}
                key={item.month}
                onClick={() =>
                  selectedYear === currentYear && Number(item.month) >= currentMonth
                    ? undefined
                    : handleSelectPayslipMonth(item.month)
                }>
                <Typography.Text size="14" weight="500">
                  {MONTH_NAMES[Number(item.month) - 1]}
                </Typography.Text>
                <Typography.Text>
                  {item.count} {t('1c_synchronization_payslip_count')}
                </Typography.Text>
              </Flex>
            ))}
          </Flex>
        </WithLoader>
      </Flex>
    </Flex>
  );
};

export default PayslipSync;
