import { useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { EMPLOYEE_GROUPS_ROUTE } from 'routes/employee-groups/list';
import { t } from 'tools/i18n';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import {
  useCreateGroupMutation,
  useUpdateGroupMutation,
} from 'services/employee-groups-new/employeeGroupsNewApiService';
import { UpdateGroupByIdRequest } from 'services/employee-groups-new/employeeGroupsNewApiService.types';
import { useAppSelector } from 'store';

import {
  EMPLOYEE_GROUPS_FORM_ENUM,
  EmployeeGroupsFormResponse,
  getResultNode,
} from './employeeGroupsCreate.utils';

interface UseEmployeeGroupsCreateProps {
  title: string;
}

const useEmployeeGroupsCreate = (props: UseEmployeeGroupsCreateProps) => {
  const { title } = props;

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [updateGroup] = useUpdateGroupMutation();
  const [createGroup] = useCreateGroupMutation();

  const { checkedNodes, partialChecked, employees } = useAppSelector(
    (store) => store.employeeGroupsSlice,
  );

  const { mappedChecked, mappedPartial } = useMemo(() => {
    const mappedChecked = checkedNodes.map(getResultNode);
    const mappedPartial = partialChecked.map(getResultNode);

    return { mappedChecked, mappedPartial };
  }, [checkedNodes, partialChecked]);

  const handleUpdate = useHandleRequestWithModal({
    onSuccessModalConfig: {},
  });

  const handleCreate = useHandleRequestWithModal({
    onSuccess: (data?: { id: string }) => {
      if (data) {
        navigate(`${EMPLOYEE_GROUPS_ROUTE}/${data.id}`);
      }
    },
    onSuccessModalConfig: {
      title: {
        children: t('common_added_successfully'),
      },
    },
  });

  const handleUpdateGroup = (formResponse: EmployeeGroupsFormResponse) => {
    if (id) {
      const updateGroupProps: UpdateGroupByIdRequest = {
        id,
        body: {
          title: title,
          employersData: {
            partialChecked: mappedPartial,
            checked: mappedChecked,
          },
          employeesData: employees,
          shiftWorker: formResponse[EMPLOYEE_GROUPS_FORM_ENUM.SHIFT_WORKER],
          linkSettlement: formResponse[EMPLOYEE_GROUPS_FORM_ENUM.LINK_SETTLEMENT],
          isSsoRegistered: formResponse[EMPLOYEE_GROUPS_FORM_ENUM.IS_SSO_REGISTERED],
        },
      };

      updateGroup(updateGroupProps).then(handleUpdate);
    }
  };

  const handleCreateGroup = (formResponse: EmployeeGroupsFormResponse) => {
    createGroup({
      title: formResponse.name,
      employersData: {
        partialChecked: mappedPartial,
        checked: mappedChecked,
      },
      employeesData: employees,
      shiftWorker: formResponse[EMPLOYEE_GROUPS_FORM_ENUM.SHIFT_WORKER],
      linkSettlement: formResponse[EMPLOYEE_GROUPS_FORM_ENUM.LINK_SETTLEMENT],
      isSsoRegistered: formResponse[EMPLOYEE_GROUPS_FORM_ENUM.IS_SSO_REGISTERED],
    }).then(handleCreate);
  };

  return { handleUpdateGroup, handleCreateGroup };
};

export default useEmployeeGroupsCreate;
