import { prepareQueryParams } from 'utils/prepareQueryParams';

import { downloadFileUsingRtk } from 'services/utils';

import {
  DownloadApprovalMapReportPropsType,
  DownloadApprovalMapReportResponseType,
  DownloadAssignmentsReportPropsType,
  DownloadAssignmentsReportResponseType,
  DownloadInquiriesReportPropsType,
  DownloadInquiriesReportResponseType,
  DownloadKedoTaskReportPropsType,
  DownloadKedoTaskReportResponseType,
  DownloadSocialTaskReportPropsType,
  DownloadSocialTaskReportResponseType,
} from './reportApiService.types';

import cInitialApiService from '../cApiService';
import { createReportUrl } from './utils';

const reportApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    downloadSocialTaskReport: builder.mutation<
      DownloadSocialTaskReportResponseType,
      DownloadSocialTaskReportPropsType
    >({
      query({ filename, ...body }) {
        return {
          url: createReportUrl('/social-task'),
          method: 'POST',
          body,
          responseHandler: downloadFileUsingRtk(filename),
          cache: 'no-cache',
        };
      },
    }),
    downloadInquiriesReport: builder.mutation<
      DownloadInquiriesReportResponseType,
      DownloadInquiriesReportPropsType
    >({
      query({ filename, ...body }) {
        return {
          url: createReportUrl('/kedo-task-inquiries'),
          method: 'POST',
          body,
          responseHandler: downloadFileUsingRtk(filename),
          cache: 'no-cache',
        };
      },
    }),
    downloadKedoTaskReport: builder.mutation<
      DownloadKedoTaskReportResponseType,
      DownloadKedoTaskReportPropsType
    >({
      query({ filename, ...body }) {
        return {
          url: createReportUrl('/kedo-task/statistics'),
          method: 'POST',
          body,
          responseHandler: downloadFileUsingRtk(filename),
          cache: 'no-cache',
        };
      },
    }),
    downloadAssignmentsReport: builder.mutation<
      DownloadAssignmentsReportResponseType,
      DownloadAssignmentsReportPropsType
    >({
      query({ filename, ...body }) {
        return {
          url: createReportUrl('/me/task-assignment'),
          method: 'POST',
          body,
          responseHandler: downloadFileUsingRtk(filename),
          cache: 'no-cache',
        };
      },
    }),
    downloadApprovalMapReport: builder.query<
      DownloadApprovalMapReportResponseType,
      DownloadApprovalMapReportPropsType
    >({
      query: ({ filename, ...params }) => ({
        url: `${createReportUrl('/work-tour/approval-map')}${prepareQueryParams(params)}`,
        responseHandler: downloadFileUsingRtk(filename),
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useDownloadSocialTaskReportMutation,
  useDownloadKedoTaskReportMutation,
  useDownloadAssignmentsReportMutation,
  useLazyDownloadApprovalMapReportQuery,
  useDownloadInquiriesReportMutation,
} = reportApiService;
