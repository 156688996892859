import { kedoRoles } from 'roles/documents.roles';
import { RouteConfig } from 'routes/routes';

import DocumentsDeclarations from 'pages/documents/documents-declarations';
import DocumentsDeclarationsEdo from 'pages/documents/documents-declarations/documents-declarations-edo';
import DocumentsDeclarationsSocial from 'pages/documents/documents-declarations/documents-declarations-social';
import DocumentsKedoReviewTaskDetails from 'pages/documents/documents-kedo-review-tasks/documents-kedo-review-task-details';
import Settings1cInstances from 'pages/settings/settings-1c-instances';
import Settings1cInstanceCreate from 'pages/settings/settings-1c-instances/settings-1c-instance-create';
import Settings1cInstanceDetails from 'pages/settings/settings-1c-instances/settings-1c-instance-details';
import SettingsCertificationAuthorities from 'pages/settings/settings-certification-authorities';
import SettingsLnaEmployment from 'pages/settings/settings-lna-employment';
import SettingsLnaEmploymentDetails from 'pages/settings/settings-lna-employment/settings-lna-employment-details';
import SettingsPositionDirectory from 'pages/settings/settings-position-directory';
import SettingsPositionDirectoryDetails from 'pages/settings/settings-position-directory/settings-position-directory-details';
import SettingsTelegramChannels from 'pages/settings/settings-telegram-channels';
import SettingsTelegramAdd from 'pages/settings/settings-telegram-channels/settings-telegram/settings-telegram-add';
import SettingsTelegramDetails from 'pages/settings/settings-telegram-channels/settings-telegram/settings-telegram-details';
import SettingsUserAgreements from 'pages/settings/settings-user-agreements';

import { GLOBAL_ROLES } from 'types/roles.types';

import UserAgreementsDetails from '../../pages/settings/settings-user-agreements/user-agreements-details';
import {
  SETTINGS_1C_INSTANCES_CREATE_ROUTE,
  SETTINGS_1C_INSTANCES_DETAILS_ROUTE,
  SETTINGS_1C_INSTANCES_ROUTE,
  SETTINGS_CERTIFICATION_AUTHORITIES,
  SETTINGS_DECLARATIONS_EDO_BY_ID_ROUTE,
  SETTINGS_DECLARATIONS_EDO_ROUTE,
  SETTINGS_DECLARATIONS_KEDO_BY_ID_ROUTE,
  SETTINGS_DECLARATIONS_KEDO_ROUTE,
  SETTINGS_DECLARATIONS_ROUTE,
  SETTINGS_DECLARATIONS_SOCIAL_BY_ID_ROUTE,
  SETTINGS_DECLARATIONS_SOCIAL_ROUTE,
  SETTINGS_DECLARATIONS_TYPE_LOCAL_ROUTE,
  SETTINGS_DECLARATIONS_TYPE_TAB_ROUTE,
  SETTINGS_LNA_EMPLOYMENT_DETAILS_ROUTE,
  SETTINGS_LNA_EMPLOYMENT_INSTANCE_ROUTE,
  SETTINGS_LNA_EMPLOYMENT_ROUTE,
  SETTINGS_POSITION_DIRECTORY_DETAILS_ROUTE,
  SETTINGS_POSITION_DIRECTORY_ROUTE,
  SETTINGS_TELEGRAM_CHANNELS_ROUTE,
  SETTINGS_TELEGRAM_CHANNEL_ADD_ROUTE,
  SETTINGS_TELEGRAM_CHANNEL_DETAILS_ROUTE,
  SETTINGS_USER_AGREEMENTS_DETAILS_ROUTE,
  SETTINGS_USER_AGREEMENTS_ROUTE,
} from './list';

export const settingsRoutes: RouteConfig[] = [
  {
    path: SETTINGS_DECLARATIONS_ROUTE,
    element: <DocumentsDeclarations />,
    ...kedoRoles,
  },
  {
    path: SETTINGS_DECLARATIONS_TYPE_TAB_ROUTE,
    element: <DocumentsDeclarations />,
    ...kedoRoles,
  },
  {
    path: SETTINGS_DECLARATIONS_TYPE_LOCAL_ROUTE,
    element: <DocumentsDeclarations />,
    ...kedoRoles,
  },
  {
    path: SETTINGS_DECLARATIONS_EDO_ROUTE,
    element: <DocumentsDeclarationsEdo />,
    ...kedoRoles,
  },
  {
    path: SETTINGS_DECLARATIONS_KEDO_ROUTE,
    element: <DocumentsDeclarationsEdo />,
    ...kedoRoles,
  },
  {
    path: SETTINGS_DECLARATIONS_SOCIAL_ROUTE,
    element: <DocumentsDeclarationsSocial />,
    ...kedoRoles,
  },
  {
    path: SETTINGS_DECLARATIONS_EDO_BY_ID_ROUTE,
    element: <DocumentsDeclarationsEdo />,
    ...kedoRoles,
  },
  {
    path: SETTINGS_DECLARATIONS_KEDO_BY_ID_ROUTE,
    element: <DocumentsDeclarationsEdo />,
    ...kedoRoles,
  },
  {
    path: SETTINGS_DECLARATIONS_SOCIAL_BY_ID_ROUTE,
    element: <DocumentsDeclarationsSocial />,
    ...kedoRoles,
  },
  {
    path: SETTINGS_1C_INSTANCES_ROUTE,
    element: <Settings1cInstances />,
    roles: [GLOBAL_ROLES.MODERATOR, GLOBAL_ROLES.ADMIN, GLOBAL_ROLES.ADMIN_MOBILE],
  },
  {
    path: SETTINGS_1C_INSTANCES_CREATE_ROUTE,
    element: <Settings1cInstanceCreate />,
    roles: [GLOBAL_ROLES.MODERATOR, GLOBAL_ROLES.ADMIN, GLOBAL_ROLES.ADMIN_MOBILE],
  },
  {
    path: SETTINGS_1C_INSTANCES_DETAILS_ROUTE,
    element: <Settings1cInstanceDetails />,
    roles: [GLOBAL_ROLES.MODERATOR, GLOBAL_ROLES.ADMIN, GLOBAL_ROLES.ADMIN_MOBILE],
  },
  {
    path: SETTINGS_TELEGRAM_CHANNELS_ROUTE,
    element: <SettingsTelegramChannels />,
    roles: [GLOBAL_ROLES.MODERATOR, GLOBAL_ROLES.ADMIN, GLOBAL_ROLES.ADMIN_MOBILE],
  },
  {
    path: SETTINGS_TELEGRAM_CHANNEL_ADD_ROUTE,
    element: <SettingsTelegramAdd />,
    roles: [GLOBAL_ROLES.ADMIN, GLOBAL_ROLES.ADMIN_MOBILE],
  },
  {
    path: SETTINGS_TELEGRAM_CHANNEL_DETAILS_ROUTE,
    element: <SettingsTelegramDetails />,
    roles: [GLOBAL_ROLES.ADMIN, GLOBAL_ROLES.ADMIN_MOBILE],
  },
  {
    path: SETTINGS_LNA_EMPLOYMENT_ROUTE,
    element: <SettingsLnaEmployment />,
    roles: [
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.KEDO_MODERATOR,
    ],
  },
  {
    path: SETTINGS_LNA_EMPLOYMENT_INSTANCE_ROUTE,
    element: <SettingsLnaEmploymentDetails />,
    roles: [
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.KEDO_MODERATOR,
    ],
  },
  {
    path: SETTINGS_LNA_EMPLOYMENT_DETAILS_ROUTE,
    element: <DocumentsKedoReviewTaskDetails />,
    roles: [
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.KEDO_MODERATOR,
    ],
  },
  {
    path: SETTINGS_POSITION_DIRECTORY_ROUTE,
    element: <SettingsPositionDirectory />,
    roles: [GLOBAL_ROLES.MODERATOR_TOPS_ANKETA, GLOBAL_ROLES.ADMIN, GLOBAL_ROLES.ADMIN_MOBILE],
  },
  {
    path: SETTINGS_POSITION_DIRECTORY_DETAILS_ROUTE,
    element: <SettingsPositionDirectoryDetails />,
    roles: [GLOBAL_ROLES.MODERATOR_TOPS_ANKETA, GLOBAL_ROLES.ADMIN, GLOBAL_ROLES.ADMIN_MOBILE],
  },
  {
    path: SETTINGS_CERTIFICATION_AUTHORITIES,
    element: <SettingsCertificationAuthorities />,
    roles: [GLOBAL_ROLES.ADMIN, GLOBAL_ROLES.ADMIN_MOBILE],
  },
  {
    path: SETTINGS_USER_AGREEMENTS_ROUTE,
    element: <SettingsUserAgreements />,
    roles: [GLOBAL_ROLES.ADMIN],
  },
  {
    path: SETTINGS_USER_AGREEMENTS_DETAILS_ROUTE,
    element: <UserAgreementsDetails />,
    roles: [GLOBAL_ROLES.ADMIN],
  },
];
