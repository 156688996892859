import { FC, useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetAgreementConsentsQuery } from 'services/policy-agreement/policyAgreementApiService';
import { GetAgreementConsentsPropsType } from 'services/policy-agreement/policyAgreementApiService.types';

import { Form } from 'gazprom-ui-lib';

import { Empty } from 'components/empty';
import FiltersV2 from 'containers/filters-v2';

import Filters from './filters';
import { DEFAULT_FORM_STATE, FormResponseType } from './tableConsent.utils';
import useColumns from './useColumns.hook';

interface TableConsentProps {
  id?: string;
}

const TableConsent: FC<TableConsentProps> = (props) => {
  const { id } = props;
  const [getConsents, { data, isFetching, isLoading, isUninitialized }] =
    useLazyGetAgreementConsentsQuery();

  const [form] = Form.useForm<FormResponseType>();
  const formData: FormResponseType | undefined = Form.useWatch([], form);

  const { debouncedValue: debouncedFormData, isDebouncing } = useDebounce(formData);

  useEffect(() => {
    if (debouncedFormData?.size) {
      const { search, page, size } = debouncedFormData;

      const initialRequestBody: GetAgreementConsentsPropsType = {
        page,
        size,
        sort: 'consentDate,DESC',
        search: search,
        id: id!,
      };

      getConsents(initialRequestBody);
    }
  }, [getConsents, debouncedFormData, id]);

  const columns = useColumns();

  const emptyText = <Empty title="common_empty_title" />;

  return (
    <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
      <Filters />
      <FiltersV2.Table
        columns={columns}
        isInitialLoading={isUninitialized || isLoading}
        dataSource={data?.items}
        total={data?.totalCount}
        loading={isFetching || isDebouncing}
        rowKey="id"
        showSorterTooltip={false}
        locale={{ emptyText }}
      />
    </FiltersV2>
  );
};

export default TableConsent;
