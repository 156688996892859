import { Suspense, lazy } from 'react';

import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { Fragment } from 'react/jsx-runtime';

import { resetQoa } from 'slices/surveysSlice';

import { Button, Flex, Tooltip } from 'gazprom-ui-lib';

import DownloadProgressModal from 'components/download-progress-modal';
import RangePicker, { OnDateChangeValueType } from 'components/range-picker';
import CompanyFilters from 'containers/company-filters';
import WithLoader from 'containers/wrappers/with-loader';

import Empty from 'pages/surveys/surveys-details/containers/stats-tab/containers/empty';
import Renderer from 'pages/surveys/surveys-details/containers/stats-tab/containers/renderer';

import { MappedFreeQuestionType, QuestionStatsType } from 'types/surveys.types';

import s from './surveysSingle.module.scss';
import { RequestBody } from './surveysSingle.utils';
import useStats from './useStats.hook';

const SurveysSingleRespondents = lazy(() => import('./containers/respondents'));

const SurveysSingle = () => {
  const dispatch = useDispatch();

  const {
    questionStatsResult,
    isDataLoading,
    requestBody,
    qoa,
    isReportDownloadingInProgress,
    setRequestBody,
    handleDownLoadReport,
  } = useStats();

  const hasData = questionStatsResult.length > 0;

  const handleChange = (key: keyof RequestBody) => (e: string) => {
    setRequestBody((prevState) => ({ ...prevState, [key]: e }));
  };

  const handleCompanyChange = (e: string) => {
    setRequestBody((prevState) => ({ ...prevState, company: e, staff: null, division: null }));
  };

  const onDateChange = (value: OnDateChangeValueType) => {
    const [from, to] = value;

    setRequestBody((prevState) => ({ ...prevState, from, to }));
  };

  const renderQuestions = (question: QuestionStatsType | MappedFreeQuestionType, index: number) => (
    <Renderer
      question={question}
      index={index}
      requestBody={requestBody}
      key={question.freeQuestionId + question.title}
    />
  );

  return (
    <>
      <Flex gap="16" className={s.filters} wrap="wrap">
        <RangePicker onDateChange={onDateChange} />
        <Button
          type="link"
          leftIcon="download"
          className={s.download}
          onClick={handleDownLoadReport}>
          {t('common_download_report_by_survey')}
        </Button>

        <CompanyFilters
          company={requestBody.company}
          position={requestBody.staff}
          division={requestBody.division}
          handleCompanyChange={handleCompanyChange}
          handleDivisionChange={handleChange('division')}
          handleStaffChange={handleChange('staff')}
        />
      </Flex>
      <div className={s.container}>
        <WithLoader isLoading={isDataLoading}>
          {hasData ? questionStatsResult.map(renderQuestions) : <Empty />}
          {hasData && (
            <Suspense fallback={<Fragment />}>
              <SurveysSingleRespondents />
            </Suspense>
          )}
          {Boolean(qoa.length) && (
            <Tooltip placement="rightTop" title={t('surveys_remove_segments')}>
              <Button onlyIcon leftIcon="delete" onClick={() => dispatch(resetQoa())} />
            </Tooltip>
          )}
        </WithLoader>
      </div>
      <DownloadProgressModal isOpen={isReportDownloadingInProgress} />
    </>
  );
};

export default SurveysSingle;
