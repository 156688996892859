import { Key } from 'react';

import { DataNode } from 'gazprom-ui-lib';

import { EmployeeType } from 'types/employee.types';
import { EmployeeGroupType } from 'types/employeeGroups.types';
import { PaginationPropsType, PaginationResponseType } from 'types/pagination.types';
import { SortType } from 'types/sort.types';

export type GetEmployeeGroupsResponseType = PaginationResponseType & {
  content: EmployeeGroupType[];
};
export type GetEmployeeGroupsPropsType = PaginationPropsType &
  SortType & {
    name?: string;
  };

export type GetEmployerRootResponse = DataNode[];

export type GetEmployeeGroupTreeRequest = {
  parentId: string;
  type: string | 'DIVISION';
};

export type GetEmployeeGroupTreeInitialResponse = {
  id: string;
  type: string;
  name: string;
  hasChildren: boolean;
}[];

export type GetEmployeeGroupTreeResponse = GetEmployerRootResponse;

export type GetEmployeeGroupByIdRequest = string;
export type GetEmployeeGroupByIdResponse = {
  id: string;
  title: string;
  description: string;
  employersData: {
    partialChecked: string[];
    checked: string[];
    treeData: TreeType[] | CustomTreeType[];
  };
  employeesData: string[];
  createdAt: string;
  updatedAt: string;
  creator: {
    id: string;
    lastName: string;
    firstName: string;
    patronymic: string;
    ssoRegistered: boolean;
  };
  editor: {
    id: string;
    lastName: string;
    firstName: string;
    patronymic: string;
    ssoRegistered: boolean;
  };
  shiftWorker?: EMPLOYEE_GROUPS_FILTER_ENUM;
  linkSettlement?: EMPLOYEE_GROUPS_FILTER_ENUM;
  isSsoRegistered?: EMPLOYEE_GROUPS_FILTER_ENUM;
};

export type GetEmployeeGroupsMembersByGroupsIdsPropsType = PaginationPropsType & {
  groupIds: string[];
  text?: string;
  employerIds?: string;
  divisionIds?: string;
  positionsIds?: string;
};

export type GetEmployeeGroupsMembersByGroupsIdsResponseType = PaginationPropsType & {
  employees: EmployeeType[];
  totalCount: number;
  page: number;
  size: number;
};

export enum EMPLOYEE_GROUPS_FILTER_ENUM {
  NOT_SET = 'NOT_SET',
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export type EmployeeFormData = {
  title?: string;
  description?: string;
  shiftWorker?: EMPLOYEE_GROUPS_FILTER_ENUM;
  linkSettlement?: EMPLOYEE_GROUPS_FILTER_ENUM;
  isSsoRegistered?: EMPLOYEE_GROUPS_FILTER_ENUM;
  employersData?: {
    partialChecked: {
      id: string;
      type: string;
    }[];
    checked: {
      id: string;
      type: string;
    }[];
  };
  employeesData?: string[];
};

export type CreateGroupRequest = {
  text?: string;
  body?: EmployeeFormData;
};

export type PreviewGroupResponseType = {
  employees: EmployeeType[];
} & PaginationResponseType &
  PaginationPropsType;

export type TreeType = {
  id: string;
  type: string;
  name: string;
  hasChildren: boolean;
  children: TreeType[];
};

export type CustomTreeType = {
  title: string;
  key: string;
  type: string;
  isLeaf?: boolean;
  children?: CustomTreeType[] | null;
};

export type UpdateGroupByIdRequest = {
  id: string;
  body: EmployeeFormData;
};

export type RemoveGroupsPropsType = Key[];

export type PreviewGroupByIdResponse = PreviewGroupResponseType;
export type PreviewGroupByIdProps = {
  id: string;
  text?: string;
  employerIds?: string[];
  divisionIds?: string[];
  positionIds?: string[];
} & PaginationPropsType;

export type GetGroupCountDraftResponseType = { count: number };
export type GetGroupCountDraftPropsType = {
  employersData: {
    partialChecked: {
      id: string;
      type: string;
    }[];
    checked: {
      id: string;
      type: string;
    }[];
  };
  employeesData?: string[];
  shiftWorker?: EMPLOYEE_GROUPS_FILTER_ENUM;
  linkSettlement?: EMPLOYEE_GROUPS_FILTER_ENUM;
  isSsoRegistered?: EMPLOYEE_GROUPS_FILTER_ENUM;
};

export type GetGroupCountResponseType = { count: number };
export type GetGroupCountPropsType = { groupId: string };
