import { t } from 'tools/i18n';

import { useUpdatePolicyAgreementByIdMutation } from 'services/policy-agreement/policyAgreementApiService';
import { UpdatePolicyAgreementByIdPropsType } from 'services/policy-agreement/policyAgreementApiService.types';

import { FullPolicyAgreementType } from 'types/policyAgreement.types';

import s from './UserAgreementsDetails.module.scss';
import UserAgreementForm from './containers/form';
import {
  USER_AGREEMENT_ENUM,
  UserAgreementFormResponse,
} from './containers/form/userAgreementForm.utils';
import TableConsent from './containers/table-consent';
import TableFileHistory from './containers/table-file-history';
import {
  POLICY_AGREEMENT_CHANGE_HISTORY,
  POLICY_AGREEMENT_CONSENT_TAB,
  POLICY_AGREEMENT_SETTINGS_TAB,
  prepareFile,
} from './userAgreementsDetails.utils';

interface UseTabItemsHook {
  policyAgreement?: FullPolicyAgreementType;
}

const useTabItemsHook = (props: UseTabItemsHook) => {
  const { policyAgreement } = props;

  const [updateAgreement, { isLoading }] = useUpdatePolicyAgreementByIdMutation();

  const onSubmit = (form: UserAgreementFormResponse) => {
    const requestBody: UpdatePolicyAgreementByIdPropsType = {
      name: form[USER_AGREEMENT_ENUM.NAME],
      description: form[USER_AGREEMENT_ENUM.DESCRIPTION],
      id: policyAgreement?.id!,
      fileKey: prepareFile(form[USER_AGREEMENT_ENUM.FILE]).fileKey,
    };

    updateAgreement(requestBody);
  };

  return [
    {
      key: POLICY_AGREEMENT_SETTINGS_TAB,
      children: (
        <div className={s.content}>
          <UserAgreementForm
            userAgreement={policyAgreement}
            onSubmit={onSubmit}
            isLoading={isLoading}
          />
        </div>
      ),
      label: t('common_settings'),
    },
    {
      key: POLICY_AGREEMENT_CONSENT_TAB,
      children: <TableConsent id={policyAgreement?.id} />,
      label: t('user_agreement_assign_list'),
    },
    {
      key: POLICY_AGREEMENT_CHANGE_HISTORY,
      children: <TableFileHistory id={policyAgreement?.id} />,
      label: t('user_agreement_change_history'),
    },
  ];
};

export default useTabItemsHook;
