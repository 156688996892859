import { userManager } from '../../utils/keycloak';

export const checkExtension = () => {
  return !!window.isCryptoProExtensionLoaded;
};

export const checkPlugin = async () => {
  try {
    const oAbout = await window.cadesplugin.CreateObjectAsync('CAdESCOM.About');
    const pluginVersion = await oAbout.PluginVersion;
    const versionString = await pluginVersion.toString();

    return !!versionString;
  } catch (e) {
    return false;
  }
};

export const checkCryptoProvider = async () => {
  try {
    const oAbout = await window.cadesplugin.CreateObjectAsync('CAdESCOM.About');
    await oAbout.CSPName(80);

    return true;
  } catch (err) {
    return false;
  }
};

export const signString = async (certName: string, str: string) => {
  const oStore = await window.cadesplugin.CreateObjectAsync('CAdESCOM.Store');
  await oStore.Open(
    window.cadesplugin.CAPICOM_CURRENT_USER_STORE,
    window.cadesplugin.CAPICOM_MY_STORE,
    window.cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
  );

  const oStoreCerts = await oStore.Certificates;
  const certCnt = await oStoreCerts.Count;

  let oCertificate;

  for (let i = 1; i <= certCnt; i++) {
    try {
      const cert = await oStoreCerts.Item(i);
      const subjectName = await cert.SubjectName;
      const validFrom = new Date(await cert.ValidFromDate);
      const validTo = new Date(await cert.ValidToDate);

      if (`${validFrom.toString()}.${validTo.toString()}.${subjectName}` === certName) {
        oCertificate = cert;
      }
    } catch (e) {
      console.log(e);
    }
  }

  if (!oCertificate) {
    return;
  }

  const oSigner = await window.cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');

  const TSP_ADDRESS = process.env.?.trim();

  if (TSP_ADDRESS) {
    try {
      oSigner.propset_TSPAddress(TSP_ADDRESS);
    } catch (e) {
      console.log(e);
    }
  }

  await oSigner.propset_Certificate(oCertificate);
  const CHECK_CERTIFICATE = process.env.false?.trim();
  await oSigner.propset_CheckCertificate(CHECK_CERTIFICATE === 'true');
  await oSigner.propset_Options(window.cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN);

  const oSignedData = await window.cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData');
  await oSignedData.propset_ContentEncoding(window.cadesplugin.CADESCOM_STRING_TO_UCS2LE);
  await oSignedData.propset_Content(str);

  try {
    return await oSignedData.SignCades(
      oSigner,
      TSP_ADDRESS
        ? window.cadesplugin.CADESCOM_CADES_X_LONG_TYPE_1
        : window.cadesplugin.CADESCOM_CADES_BES,
      true,
    );
  } catch (err) {
    console.log(err);
    throw new Error();
  }
};

// const checkSubjectName = (subjectName: string) => {
//   let checkOgrn = false,
//     checkO = false,
//     checkOU = false,
//     checkT = false;
//
//   subjectName.split(', ').forEach((item) => {
//     const [key, value] = item.split('=');
//     if (value) {
//       if (key === 'OGRN') {
//         checkOgrn = true;
//       } else if (key === 'O') {
//         checkO = true;
//       } else if (key === 'OU') {
//         checkOU = true;
//       } else if (key === 'T') {
//         checkT = true;
//       }
//     }
//   });
//
//   return checkOgrn && checkO && checkOU && checkT;
// };

export const getCertificates = async () => {
  const oStore = await window.cadesplugin.CreateObjectAsync('CAdESCOM.store');

  try {
    await oStore.Open();
  } catch (ex) {
    console.log(ex);
  }

  const oStoreCertificates = await oStore.Certificates;
  const certCnt = await oStoreCertificates.Count;

  let certs = [];

  if (certCnt === 0) {
    throw new Error();
  }

  for (let i = 1; i <= certCnt; i++) {
    try {
      const cert = await oStoreCertificates.Item(i);
      const serialNumber = await cert.SerialNumber;
      const validFrom = new Date(await cert.ValidFromDate);
      const validTo = new Date(await cert.ValidToDate);
      const subjectName = await cert.SubjectName;
      const issuerName = await cert.IssuerName;

      const pubKey = await cert.PublicKey();
      const algo = await pubKey.Algorithm;
      const algoName = await algo.FriendlyName;

      // let subjectNameIsValid = checkSubjectName(subjectName);

      let valid = true;

      if (process.env.false?.trim() !== 'false') {
        valid =
          validFrom.getTime() < Date.now() &&
          // subjectNameIsValid &&
          validTo.getTime() > Date.now() &&
          !!serialNumber &&
          !!algoName;
      }

      certs.push({
        validFrom,
        validTo,
        subjectName,
        issuerName,
        algoName,
        valid,
      });
    } catch (ex) {
      return;
    }
  }

  return certs;
};

export const handleDownloadAsBlob = async (id: string, fileKey: string) => {
  const user = await userManager.getUser();
  const token = user?.access_token as string;

  return fetch(
    `${process.env.https://admin-gateway.dev.tockl.ru/api/v1/}kedo-file/assignment/${id}/download?fileKey=${fileKey}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((res) => res.blob());
};
